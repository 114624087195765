export const ACTIONS = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
}

export const login = (user) => ({
  type: ACTIONS.LOGIN,
  user,
})

export const logout = () => ({
  type: ACTIONS.LOGOUT,
})