import React from 'react'
import { ThemeProvider } from '@smooth-ui/core-sc'
import { Provider } from 'react-redux'
import { createStore } from 'redux'

import { theme } from './style/theme'
import rootReducer from './reducers'
import { getUser } from './services/auth'
import { isBrowser } from './utils/utils'

export default ({element}) => {
  const initialState = {
    user: getUser()
  }
  const store = createStore(
    rootReducer,
    initialState
  )

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {element}
      </ThemeProvider>
    </Provider>
  )
}