import { createGlobalStyle } from 'styled-components'
import { th } from '@smooth-ui/core-sc'

export const GlobalStyle = createGlobalStyle`
  body {
    background: #fff;
    font-family: 'Roboto Condensed';
    font-size: 18px;
    color: ${th('gray800')};
  }

  a {
    text-decoration: none;
    color: ${th('gray800')};

    &:hover {
      color: ${th('primary')};
    }
  }

  img {
    max-width: 100%;
  }

  h1 {
    font-size: ${th('h1FontSize')};
    margin-bottom: 0;
    line-height: 64px;
    color: ${th('gray300')};
  }

  h2 {
    font-size: ${th('h2FontSize')};
    font-family: 'Roboto Condensed';
    font-weight: bold;
    margin: 0 0 10px 0;
    line-height: 41px;
  }

  h3 {
    font-size: ${th('h3FontSize')};
    font-family: 'Roboto Condensed';
    font-weight: 100;
    margin: 0;
  }
  
  h5 {
    font-size: 11px;
    font-family: 'Roboto Condensed';
    font-weight: 100;
    margin: 0;
  }
  
`

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1290
}

export const gridMaxWidths = {
  sm: '540px',
  md: '740px',
  lg: '960px',
  xl: '1282px',
}

export const theme = {
  primary: '#F06449',
  primaryHover: '#F04F30',
  secondary: '#D6EB66',
  white: '#fff',
  white300: 'rgba(255,255,255, 0.3)',
  white600: 'rgba(255,255,255, 0.6)',
  white800: 'rgba(255,255,255, 0.8)',
  white900: 'rgba(255,255,255, 0.9)',
  gray100: 'rgba(26,26,26,0.1)',
  gray300: 'rgba(26,26,26,0.3)',
  gray500: 'rgba(26,26,26,0.5)',
  gray600: 'rgba(26,26,26,0.6)',
  gray800: 'rgba(26,26,26,0.8)',
  gray900: 'rgba(26,26,26,0.9)',
  gray: 'rgba(26,26,26,1)',
  fontWeightSemiBold: '600',

  breakpoints,
  gridMaxWidths,
  gridGutter: 3,

  h1FontSize: '64px',
  h2FontSize: '35px',
  h3FontSize: '24px',

  borderRadius: 0,
  borderRadiusLg: 0,
  borderRadiusSm: 0,
}
