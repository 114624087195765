import { combineReducers } from 'redux'

import { logout, setUser } from '../services/auth'
import { ACTIONS } from '../actions/actions'

export const user = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN:
      setUser(action.user) // side effect
      return action.user
    case ACTIONS.LOGOUT:
      logout() // side effect
      return {}
  }
  return state
}

export default combineReducers({
  user
})