import { isBrowser } from '../utils/utils'

const USER_STORAGE = 'user'

export const getUser = () =>
  isBrowser() && JSON.parse(localStorage.getItem(USER_STORAGE))

export const isLogged = () => {
  const user = getUser()
  return user && user.authentication_token
}

export const setUser = (userData) => {
  localStorage.setItem(USER_STORAGE, JSON.stringify(userData))
}

export const logout = () =>
  localStorage.setItem(USER_STORAGE, JSON.stringify({}))