import moment from 'moment';


export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const renderDate = date => {
  const finalDate = moment(date).format("MMMM Do YYYY");
  return finalDate;
}

export const isBrowser = () => typeof window !== 'undefined'

export const toSeconds = time => {
  const segments = time.split(':')
  if (segments.length === 3) {
    const [hours = 0, minutes = 0, seconds = 0] = segments
    return (
      parseInt(hours, 10) * 3600 +
      parseInt(minutes, 10) * 60 +
      parseInt(seconds, 10)
    )
  } else if (segments.length === 2) {
    const [minutes = 0, seconds = 0] = segments
    return parseInt(minutes, 10) * 60 + parseInt(seconds, 10)
  }
}

export const fromSeconds = seconds => {
  const date = new Date(seconds * 1000)
  const hours = date.getUTCHours()
  return hours
    ? `${hours}:${twoDigitsFrom(date.getUTCMinutes())}:${twoDigitsFrom(
        date.getUTCSeconds()
      )}`
    : `${twoDigitsFrom(date.getUTCMinutes())}:${twoDigitsFrom(
        date.getUTCSeconds()
      )}`
}

export const twoDigitsFrom = nr => {
  return nr < 10 && nr > 0 ? `0${nr}` : nr
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}
